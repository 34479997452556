@import '../../styles/variables';

.headerContainer {
  background-color: $blue-dark;
  border-bottom: 1px solid $blue-light;
  .title {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .logoContainer {
    display: none;
    @media (min-width: $breakpoint-md) {
      display: flex;
      align-self: center;
      margin: 1rem 0;
      .logo {
        width: 10rem;
      }
    }
  }

  .navigation {
    @media (min-width: $breakpoint-md) {
      display: flex;
      align-items: center;
    }
    .list {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;
      @media (min-width: $breakpoint-md) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 4.5rem;
      }
      .items {
        color: $secondary;
        margin: 1rem;
        text-align: center;
        @media (min-width: $breakpoint-md) {
          margin: 0 0.5rem;
        }
        .links {
          display: inline-block;
          color: $secondary;
          text-decoration: none;
          text-transform: uppercase;
          .icon {
            margin-right: 0.5rem;
          }
          &:hover {
            color: $grey-light;
          }
          @media (min-width: $breakpoint-md) {
          }
        }
      }
    }
  }
}
