// Embeded fonts
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500;700&display=swap');

// Local fonts
@font-face {
  font-family: 'joystix';
  src: url('../fonts/joystix/joystixmonospace.eot');
  src: url('../fonts/joystix/joystixmonospace.eot?#iefix') format('embedded-opentype'),
    url('../fonts/joystix/joystixmonospace.woff2') format('woff2'),
    url('../fonts/joystix/joystixmonospace.woff') format('woff'),
    url('../fonts/joystix/joystixmonospace.ttf') format('truetype'),
    url('../fonts/joystix/joystixmonospace.svg#svgFontName') format('svg');
}
