@import '../../styles/variables';

.event {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    width: 100%;
    margin-bottom: 1rem;
    @media (min-width: $breakpoint-md) {
      width: 30rem;
    }
  }
  .subText {
    color: $secondary;
    font-family: $font-default;
    text-align: center;
    font-size: 1.5rem;
  }
}
