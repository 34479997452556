@import '../../styles/variables';

.brandContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  @media (min-width: $breakpoint-md) {
    margin-top: 5rem;
  }
  @media (min-width: $breakpoint-xl) {
    margin-top: 8rem;
  }
  .logo {
    width: 85%;
    @media (min-width: $breakpoint-md) {
      width: 20rem;
    }
    @media (min-width: $breakpoint-xl) {
      width: 25rem;
    }
  }
}
