// Tones
$white: #f1f1f1;
$grey-light: #e7e8e9;
$grey: #ccc;
$grey-medium: #999;
$grey-dark: #333132;
$black: #010206;

// Colors
$blue-light: #3f6589;
$blue: #122f4f;
$blue-dark: #092543;
$skull: #fbf7bf;
$red: #af2a37;
$green: #4caf50;
$yellow: #d7be28;

$primary: $blue;
$secondary: $grey;

$sucess: $green;
$warning: $yellow;
$danger: $red;
$light: $grey-light;
$dark: $grey-dark;

// Grid Breakpoints
$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// Available fonts
$font-default: 'Ubuntu', sans-serif;
$font-8bit: 'joystix';
