// Imports
@import './../../node_modules/normalize.css/normalize.css';
@import './../../node_modules/flexboxgrid/dist/flexboxgrid.min.css';

@import 'variables';
@import 'fonts';

// Initial styles
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Ubuntu', sans-serif;
  background-color: $black;
  @media (min-width: $breakpoint-md) {
    background-image: url('../images/sagen.jpg');
    background-repeat: no-repeat;
    background-size: contains;
    background-position: top center;
  }
}
