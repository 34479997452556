@import '../../styles/variables';

.container {
  color: $white;
  margin-top: 2rem;
  text-align: center;
  
  .prefix {
    font-weight: 300;
    margin-bottom: 1.5rem;

    @media (min-width: $breakpoint-md) {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .date {
    color: $yellow;
    font-size: 2rem;
  }
}