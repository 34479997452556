@import '../../styles/variables';

.countdown_container {
  display: flex;
  justify-content: center;
  color: $secondary;
  font-family: $font-default;
  font-weight: bold;
  margin: 1.5rem 0;
  @media (min-width: $breakpoint-md) {
    margin: 3rem 0;
  }
  .timer {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    @media (min-width: $breakpoint-md) {
      margin-right: 1rem;
      margin-left: 1rem;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .time {
      font-size: 2rem;
      @media (min-width: $breakpoint-xs) {
        font-size: 2.5rem;
      }
      @media (min-width: $breakpoint-md) {
        font-size: 5rem;
      }
    }
    .label {
      color: $yellow;
      font-size: 0.5rem;
      text-align: center;
      @media (min-width: $breakpoint-xs) {
        font-size: 0.65rem;
      }
      @media (min-width: $breakpoint-md) {
        font-size: 1rem;
      }
    }
  }
}
